import React, {useEffect, useState} from "react";
import {ILanding} from "../types/ILanding";
import {ILandingBlock} from "../types/ILandingBlock";
import {toNumber} from "lodash";
import SessionModel from "../stores/SessionModel";
import * as CommonActions from "./common/CommonActions";
import {EditTextBlock} from "./common/EditTextBlock";
import {EditLootBlock} from "./common/EditLootBlock";
import * as LandingAPI from "../requests/LandingAPI";
import {sanitize} from "./common/CommonActions";
import {CONFIG} from "../CONFIG";

export const Loot: React.FC<{
    landing: ILanding | undefined,
    setLanding: (landing: ILanding) => void}> = ({landing, setLanding}) => {

    const [block, setBlock] = useState<ILandingBlock | undefined>();
    const [showEdit, setShowEdit] = useState(false);
    const [showLootEdit, setShowLootEdit] = useState(false);
    const [editableBlock, setEditableBlock] = useState<ILandingBlock | undefined>();

    useEffect(() => {
        setBlock(landing?.blocks?.filter(block => { return block.type === "LOOT" })[0]);
    }, [landing]);

    const deleteLoot = (blockId: string) => {
        if (landing?.id) {
            LandingAPI.deleteBlock(landing?.id, blockId, (response) => {
                if (response.status === 200) {
                    const subBlocks = block?.subBlocks?.filter(sb => { return sb.id !== blockId});
                    setBlock({...block, subBlocks: subBlocks});
                }
            })
        }
    }

    const editLoot = (visible: boolean, block: ILandingBlock | undefined) => {
        setShowLootEdit(visible);
        setEditableBlock(block);
    }

    if (block) {
        return (
            <>
                <section className={block.isActive ? "newdes-section-standart" : "newdes-section-standart newdes-hidden-block"} id="prizes">
                    {SessionModel.canEditLanding(landing?.userId) ?
                        <div className="newdes-edit-line">
                            <div className="newdes-click-edit" onClick={() => setShowEdit(true)}>
                                <div className="newdes-edit-icon"></div>
                                <p className="newdes-edit-text" onClick={() => setShowEdit(true)}>Редактировать блок</p>
                            </div>
                            {block.isActive ?
                                <p className="newdes-hidden-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Скрыть блок</p>
                                :
                                <p className="newdes-visible-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Показать</p>
                            }
                        </div>
                        :
                        <></>
                    }
                    <h2 className="newdes-heading-block">
                        {block.title || "Призы и подарки"}
                    </h2>
                    {/*<p className="newdes-text-description">
                        {block.description || ""}
                    </p>*/}
                    <ul className="newdes-cards-list-jury">
                        {block.subBlocks?.map((block: ILandingBlock, index) =>
                            <li className="newdes-jury" key={index}>
                                {
                                    SessionModel.canEditLanding(landing?.userId) ?
                                        <div className="newdes-delete" onClick={() => deleteLoot(block.id || "")}>
                                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/delete_red.svg`} className="newdes-delete-element"/>
                                        </div>
                                        :
                                        <></>
                                }
                                <div className="newdes-prize-img-container">
                                    {
                                        SessionModel.canEditLanding(landing?.userId) ?
                                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/${block.mediaList ? block.mediaList[0].remoteMedia : ""}`} style={{cursor: "pointer"}} className="newdes-img-prize" onClick={() => editLoot(true, block)}/>
                                            :
                                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/${block.mediaList ? block.mediaList[0].remoteMedia : ""}`} alt="" className="newdes-img-prize"/>
                                    }

                                </div>
                                <div className="newdes-text-jury">
                                    <h3 className="newdes-headline-jury">
                                        {block.title}
                                    </h3>
                                    <p className="newdes-text-description" dangerouslySetInnerHTML={{__html: sanitize(block.description || "Заполните блок")}}/>
                                </div>
                            </li>
                        )}
                        {SessionModel.canEditLanding(landing?.userId) ?
                            <li className="newdes-jury-load" onClick={() => editLoot(true, undefined)}>
                                <div className="newdes-load-jury-block">
                                    <div className="newdes-load-video-plus">
                                        <div className="newdes-gor-line"></div>
                                        <div className="newdes-vert-line"></div>
                                    </div>
                                    <p className="newdes-load-video-text">
                                        Добавить новую награду
                                    </p>
                                </div>
                            </li>
                            :
                            <></>
                        }
                    </ul>
                </section>

                {showEdit ?
                    <EditTextBlock visible={showEdit} setVisible={setShowEdit} block={block} setLanding={setLanding}/>
                    :
                    <></>
                }
                {
                    showLootEdit ?
                        <EditLootBlock subBlock={editableBlock} block={block} visible={showLootEdit} setVisible={editLoot} setLanding={setLanding}/>
                        :
                        <></>
                }
            </>
        )
    } else {
        return(<></>);
    }
}