import React, {useEffect, useState} from "react";
import {Navigation} from "./Navigation";
import {TitleInfo} from "./TitleInfo";
import {Description} from "./Description";
import {Partners} from "./Partners";
import {Participants} from "./Participants";
import {Wtfa} from "./Wtfa";
import {Stages} from "./Stages";
import {Loot} from "./Loot";
import {School} from "./School";
import {Experts} from "./Experts";
import * as LandingAPI from "../requests/LandingAPI";
import {ILanding} from "../types/ILanding";
import {useParams} from "react-router-dom";
import {toNumber} from "lodash";
import {Text} from "./Text";
import {ILandingBlock} from "../types/ILandingBlock";
import SessionModel from "../stores/SessionModel";
import {ILandingBlockRequest} from "../types/ILandingBlockRequest";
import {CONFIG} from "../CONFIG";
import {getFrontUrl} from "./helper/Util";
import cookie from "js-cookie";
import API from "../requests/_axios";

export const Main = () => {

    const params = useParams();
    const [landing, setLanding] = useState<ILanding>();
    const [notFound, setNotFound] = useState<boolean | undefined>();
    const [theme, setTheme] = useState("dark");
    const [blind, setBlind] = useState(false);

    useEffect(() => {
        setInterval(() => {
            if (cookie.get("token")) {
                API.post("/sso/api/v1/user").then((response) => {
                    if (response.status !== 200) {
                        cookie.remove("token");
                        window.location.reload();
                    }
                })
            }
        }, 60000);
        if (params.challengeId) {
            LandingAPI.getLanding(params.challengeId, (response) => {
                if (response.status === 200) {
                    setLanding(response.data);
                } else {
                    setNotFound(true);
                }
            });
        }
    }, []);

    console.log(notFound);

    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.style.setProperty("--newdes-main-color", landing?.challenge?.branding?.mainColorHex ? `${landing?.challenge?.branding?.mainColorHex}` : "rgb(79, 142, 202)");
            document.documentElement.style.setProperty("--newdes-main-color-pacity", "rgba(0, 0, 0, 0.07)");
            document.documentElement.style.setProperty("--newdes-heading-color", "rgb(255, 255, 255)");
            document.documentElement.style.setProperty("--newdes-text-color", "rgb(197, 197, 197)");
            document.documentElement.style.setProperty("--newdes-background", "rgb(0, 0, 0)");
            document.documentElement.style.setProperty("--newdes-background-edit", "rgb(0, 0, 0, 0.9)");
            document.documentElement.style.setProperty("--newdes-backfround-card", "#7777a11c");
        } else if (theme === "light") {
            document.documentElement.style.setProperty("--newdes-main-color", landing?.challenge?.branding?.mainColorHex ? `${landing?.challenge?.branding?.mainColorHex}` : "rgb(79, 142, 202)");
            document.documentElement.style.setProperty("--newdes-main-color-pacity", "rgba(0, 0, 0, 0.07)");
            document.documentElement.style.setProperty("--newdes-heading-color", "rgb(0, 0, 0)");
            document.documentElement.style.setProperty("--newdes-text-color", "rgb(92, 92, 92)");
            document.documentElement.style.setProperty("--newdes-background", "white");
            document.documentElement.style.setProperty("--newdes-background-edit", "rgb(255, 255, 255, 0.9)");
            document.documentElement.style.setProperty("--newdes-backfround-card", "#7777a11c");
        }
    }, [theme, landing]);

    const switchBlind = () => {
        setTheme("dark");
        setBlind(!blind);
    }

    const addBlock = () => {
        const lbRequest: ILandingBlockRequest = {
            landingId: landing?.id,
            title: "Новый текстовый блок",
            description: "Заполните описание"
        }
        if (landing?.id) {
            LandingAPI.createBlock(landing.id, lbRequest, (response) => {
                if (response.status === 200) {
                    setLanding(response.data);
                }
            })
        }
    }

    if (landing) {
        return (
            <>
                {blind ?
                    <div>
                        <link rel="stylesheet" type="text/css" href={`${CONFIG.PUBLIC_PREFIX}/css/zrenie.css`} />
                    </div>
                    :
                    <></>
                }
                <div className="newdes-background-theme">
                </div>
                <header className="newdes-header">
                    <Navigation landing={landing} theme={theme} setTheme={setTheme}/>
                </header>
                <main className="newdes-container-wrapper">
                    <div className="newdes-flex-btn-eye">
                        {
                            blind ?
                                <div className="no-zrenie-btn" onClick={switchBlind} style={{cursor: "pointer"}}>
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/assets/img/noglasses.svg`} alt="" className="zrenie-img"/>
                                </div>
                                :
                                <div className="zrenie-btn" onClick={switchBlind} style={{cursor: "pointer"}}>
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/assets/img/glasses.svg`} alt="" className="zrenie-img"/>
                                </div>
                        }
                        <a href="https://braim.org" target="_blank" className="newdes-braim-element">BRAIM</a>
                    </div>
                    <div className="newdes-right-block">
                        {landing?.challenge?.branding?.backgroundPath ?
                            landing?.challenge.branding.backgroundPath.split('.').pop() === "jpg" ?
                                <img src={CONFIG.STORAGE_URL + "/" + landing?.challenge?.branding?.backgroundPath} className="newdes-hero-video"/>
                                :
                                <video autoPlay muted loop playsInline className="newdes-hero-video">
                                    <source src={CONFIG.STORAGE_URL + "/" + landing?.challenge?.branding?.backgroundPath}/>
                                </video>
                            :
                            <></>
                        }
                    </div>
                    <div className="newdes-left-block">
                        <TitleInfo landing={landing} setLanding={setLanding} theme={theme}/>
                        <Description landing={landing} setLanding={setLanding}/>
                        <Partners landing={landing} setLanding={setLanding} theme={theme}/>
                        <Participants landing={landing} setLanding={setLanding}/>
                        <Wtfa landing={landing} setLanding={setLanding}/>
                        <Stages landing={landing}/>
                        <Loot landing={landing} setLanding={setLanding}/>
                        <School landing={landing} setLanding={setLanding}/>
                        <Experts landing={landing} setLanding={setLanding}/>

                        {landing?.blocks?.filter(block => { return block.type === "TEXT" }).map((block: ILandingBlock, index) =>
                            <Text landing={landing} landingBlock={block} setLanding={setLanding} key={index}/>
                        )}

                        {SessionModel.canEditLanding(landing.userId) &&
                        landing?.blocks?.filter(block => { return block.type === "TEXT" }) &&
                        landing?.blocks?.filter(block => { return block.type === "TEXT" }).length < 3 ?
                            <section className="newdes-new-block" onClick={addBlock}>
                                <li className="newdes-new-block-load">
                                    <div className="newdes-load-new-block">
                                        <div className="newdes-load-video-plus-white">
                                            <div className="newdes-gor-line-new"></div>
                                            <div className="newdes-vert-line-new"></div>
                                        </div>
                                        <p className="newdes-load-video-text-white">
                                            Добавить новый текстовый блок
                                        </p>
                                    </div>
                                </li>
                            </section>
                            :
                            <></>
                        }
                    </div>
                </main>
                <footer className="newdes-footer">
                    <nav className="newdes-footer-nav">
                        <a href="https://braim.org" className="newdes-footer-nav-logo">
                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/logo_plat.svg`} alt="" className="newdes-footer-nav-logo-img"/>
                            <p className="newdes-braim-description">
                                Российская платформа инновационных соревнований
                            </p>
                        </a>

                        <ul className="newdes-footer-nav-list">
                            <li className="newdes-footer-nav-item">
                                <h3 className="newdes-headline-footer">
                                    Поддержка
                                </h3>
                            </li>
                            <li className="newdes-footer-nav-item">
                                <a href={`https://t.me/${landing?.challenge?.adminTelegram}`} target="_blank" className="newdes-footer-nav-link">Чат участников</a>
                            </li>
                            <li className="newdes-footer-nav-item">
                                <a href="https://t.me/Braim_org" target="_blank" className="newdes-footer-nav-link">Поддержка
                                    платформы Braim</a>
                            </li>
                        </ul>
                        <ul className="newdes-footer-nav-list">
                            <li className="newdes-footer-nav-item">
                                <h3 className="newdes-headline-footer">
                                    Контакты
                                </h3>
                            </li>
                            <li className="newdes-footer-nav-item">
                                <a href="tel:+74997033949" className="newdes-footer-nav-link">+7 (499) 703-39-49</a>
                            </li>
                            <li className="newdes-footer-nav-item">
                                <a href="mailto:info@braim.org"
                                   className="newdes-footer-nav-link">info@braim.org</a>
                            </li>
                        </ul>
                        <ul className="newdes-footer-nav-list">
                            <li className="newdes-footer-nav-item">
                                <h3 className="newdes-headline-footer">
                                    Документы
                                </h3>
                            </li>
                            <li className="newdes-footer-nav-item">
                                <a href={landing.challenge?.rules}
                                   target="_blank" className="newdes-footer-nav-link">Правила мероприятия</a>
                            </li>
                            <li className="newdes-footer-nav-item">
                                <a href={`${window.location.origin}/storage/terms_braim.pdf`} target="_blank"
                                   className="newdes-footer-nav-link">Условия использования платформы</a>
                            </li>
                            <li className="newdes-footer-nav-item">
                                <a href={`${window.location.origin}/storage/privacy_policy.pdf`} target="_blank"
                                   className="newdes-footer-nav-link">Политика конфиденциальности Braim</a>
                            </li>

                        </ul>
                    </nav>
                    <p className="newdes-copyright">
                        © 2020-2024 Создано на BRAIM. Все права защищены.
                    </p>
                </footer>


                {SessionModel.canEditLanding(landing.userId) ?
                    <a href={`${getFrontUrl()}challenge/${landing.challengeId}`} className="newdes-settings">
                        <img src={`${CONFIG.PUBLIC_PREFIX}/images/settings_white.svg`} alt="" className="newdes-settings-icon"/>
                    </a>
                    :
                    <></>
                }
            </>
        )
    } else if (notFound) {
        return (
            <>
                <div className="newdes-background-theme">
                </div>
                <header className="newdes-header">
                    <nav className="newdes-header-nav">
                        <ul className="newdes-header-nav-list">
                            <li className="newdes-header-nav-item">
                                <a href="#main" className="newdes-header-nav-link">
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/images/home.svg`} alt="" className="newdes-header-nav-icon"/>
                                </a>
                            </li>
                            <li className="newdes-header-nav-item">
                                <a href="#people" className="newdes-header-nav-link">
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/images/people.svg`} alt="" className="newdes-header-nav-icon"/>
                                </a>
                            </li>
                            <li className="newdes-header-nav-item">
                                <a href="#challenge" className="newdes-header-nav-link">
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/images/challenge.svg`} alt=""
                                         className="newdes-header-nav-icon"/>
                                </a>
                            </li>
                            <li className="newdes-header-nav-item">
                                <a href="#stages" className="newdes-header-nav-link">
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/images/stage.svg`} alt="" className="newdes-header-nav-icon"/>
                                </a>
                            </li>

                            <li className="newdes-header-nav-item">
                                <a href="#prizes" className="newdes-header-nav-link">
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/images/prize.svg`} alt=""
                                         className="newdes-header-nav-icon"/>
                                </a>
                            </li>
                            <li className="newdes-header-nav-item">
                                <a href="#video" className="newdes-header-nav-link">
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/images/video.svg`} alt=""
                                         className="newdes-header-nav-icon"/>
                                </a>
                            </li>
                        </ul>
                        {/*<div className="newdes-header-nav-link-theme">
                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/moon.svg`} id="newdes-icon-theme-white" alt=""
                                 className="newdes-header-nav-icon-theme" style={{display: theme === "dark" ? "none" : ""}} onClick={() => setTheme("dark")}/>
                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/sun.svg`} id="newdes-icon-theme-dark" alt=""
                                 className="newdes-header-nav-icon-theme-dark" style={{display: theme === "light" ? "none" : ""}} onClick={() => setTheme("light")}/>
                        </div>*/}
                    </nav>
                </header>
                <main className="newdes-container-wrapper">
                    <div className="newdes-flex-btn-eye">
                        {/*<div className="zrenie-btn" id="zrenie">
                            <img src="assets/img/glasses.svg" alt="" className="zrenie-img">
                        </div>
                        <div className="no-zrenie-btn" id="no-zrenie">
                            <img src="assets/img/noglasses.svg" alt="" className="zrenie-img">
                        </div>*/}
                        <a href="https://www.braim.org" target="_blank" className="newdes-braim-element">BRAIM</a>
                    </div>
                    <div className="newdes-right-block">
                        <video autoPlay muted loop playsInline className="newdes-hero-video">
                            <source src={`${CONFIG.PUBLIC_PREFIX}/assets/video/404.mp4`}/>
                        </video>
                    </div>
                    <div className="newdes-left-block">
                        <section className="newdes-hero" id="main">
                            <video autoPlay muted loop playsInline className="newdes-hero-video-mobile">
                                <source src={`${CONFIG.PUBLIC_PREFIX}/assets/video/404.mp4`}/>
                            </video>
                            <h1 className="newdes-hero-headline">
                                Ошибка 404
                            </h1>
                            <p className="newdes-hero-description">
                                К большому сожалению, такой страницы не существует или не существовало вообще
                            </p>
                        </section>
                    </div>


                </main>
            </>)
    } else {
        return (<></>);
    }
}