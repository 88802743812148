import React, {useEffect, useState} from "react";
import {ILandingBlock} from "../types/ILandingBlock";
import {ILanding} from "../types/ILanding";
import {formatDate} from "./helper/DateHelper";
import * as LandingAPI from "../requests/LandingAPI";
import {CONFIG} from "../CONFIG";
import {toNumber} from "lodash";
import SessionModel from "../stores/SessionModel";
import {EditTextBlock} from "./common/EditTextBlock";
import * as CommonActions from "./common/CommonActions";
import {UploadLogos} from "./common/UploadLogos";
import {sanitize} from "./common/CommonActions";

export const TitleInfo: React.FC<{
    landing: ILanding | undefined,
    setLanding: (landing: ILanding) => void,
    theme: string}> = ({landing, setLanding, theme}) => {

    const [block, setBlock] = useState<ILandingBlock | undefined>();
    const [showUpload, setShowUpload] = useState(false);
    const [lightLogoPreview, setLightLogoPreview] = useState<string | undefined>();
    const [darkLogoPreview, setDarkLogoPreview] = useState<string | undefined>();
    const [lightLogo, setLightLogo] = useState<FormData>();
    const [darkLogo, setDarkLogo] = useState<FormData>();
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        setBlock(landing?.blocks?.filter(block => { return block.type === "TITLE" })[0]);
    }, [landing]);

    const handleLightLogo = function (e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;
        if (!fileList) return;
        const formData = new FormData();
        formData.append("file", fileList[0], fileList[0].name);
        setLightLogoPreview(URL.createObjectURL(fileList[0]));
        setLightLogo(formData);

    };

    const handleDarkLogo = function (e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;
        if (!fileList) return;
        const formData = new FormData();
        formData.append("file", fileList[0], fileList[0].name);
        setDarkLogoPreview(URL.createObjectURL(fileList[0]));
        setDarkLogo(formData);
    };

    const upload = () => {
        if (darkLogo && lightLogo) {
            LandingAPI.uploadLogo(toNumber(landing?.challengeId), darkLogo, "dark", (response) => {
                if (response.status === 200) {
                    setDarkLogoPreview(undefined);
                    setDarkLogo(undefined);
                }
                LandingAPI.uploadLogo(toNumber(landing?.challengeId), lightLogo, "light", (response) => {
                    if (response.status === 200) {
                        setLightLogoPreview(undefined);
                        setLightLogo(undefined);
                        setLanding(response.data);
                        setShowUpload(false);
                    }
                });
            });
        } else if (lightLogo) {
            LandingAPI.uploadLogo(toNumber(landing?.challengeId), lightLogo, "light", (response) => {
                if (response.status === 200) {
                    setLightLogoPreview(undefined);
                    setLightLogo(undefined);
                    setLanding(response.data);
                    setShowUpload(false);
                }
            });
        } else if (darkLogo) {
            LandingAPI.uploadLogo(toNumber(landing?.challengeId), darkLogo, "dark", (response) => {
                if (response.status === 200) {
                    setDarkLogoPreview(undefined);
                    setDarkLogo(undefined);
                    setLanding(response.data);
                    setShowUpload(false);
                }
            });
        }
    }

    const cancelUpload = () => {
        setLightLogoPreview(undefined);
        setDarkLogoPreview(undefined);
        setShowUpload(false);
    }

    const deleteImage = () => {
        LandingAPI.deleteLogo(toNumber(landing?.challengeId), theme, (response) => {
            if (response.status === 200) {
                setLanding(response.data);
            }
        })
    }

    const hasDarkLogo = () => {
        return landing?.logoDark || darkLogoPreview;
    }

    const hasLightLogo = () => {
        return landing?.logoLight || lightLogoPreview;
    }

    if (block) {
        return (
            <>
                <section className={block.isActive ? "newdes-hero" : "newdes-hero newdes-hidden-block"} id="main">
                    <ul className="newdes-logo-hero-block">
                        <li className="newdes-logo-item-hero">
                            {SessionModel.canEditLanding(landing?.userId) && ((theme === "dark" && landing.logoDark) || (theme === "light" && landing.logoLight)) ?
                                <div className="newdes-delete">
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/images/delete_red.svg`} alt="" className="newdes-delete-element" onClick={deleteImage}/>
                                </div>
                                :
                                <></>
                            }
                            {theme === "dark" && (darkLogoPreview || landing?.logoDark) ?
                                <img src={darkLogoPreview || `${CONFIG.STORAGE_URL}/landing/${landing?.challenge?.id}/logo/${landing?.logoDark}`} alt="Sbertech"
                                     className="newdes-logo-item-hero-dark"/>
                                :
                                <></>
                            }
                            {theme === "light" && (lightLogoPreview || landing?.logoLight) ?
                                <img src={lightLogoPreview || `${CONFIG.STORAGE_URL}/landing/${landing?.challenge?.id}/logo/${landing?.logoLight}`} alt="Sbertech"
                                     className="newdes-logo-item-hero-white"/>
                                :
                                <></>
                            }
                        </li>
                        {SessionModel.canEditLanding(landing?.userId) ?
                            <li className="newdes-logo-item-hero">
                                <div className="newdes-insert-logo">
                                    <div className="newdes-insert-logo-label" onClick={() => setShowUpload(!showUpload)}>{theme === "dark" ?
                                            hasDarkLogo() ? "Изменить логотип" : "Добавить логотип"
                                            :
                                            hasLightLogo() ? "Изменить логотип" : "Добавить логотип"}</div>
                                </div>
                            </li>
                            :
                            <></>
                        }
                    </ul>
                    {landing?.challenge?.branding?.backgroundPath ?
                        landing?.challenge.branding.backgroundPath.split('.').pop() === "jpg" ?
                            <img src={CONFIG.STORAGE_URL + "/" + landing?.challenge?.branding?.backgroundPath} className="newdes-hero-video-mobile"/>
                            :
                            <video autoPlay muted loop playsInline className="newdes-hero-video-mobile">
                                <source src={CONFIG.STORAGE_URL + "/" + landing?.challenge?.branding?.backgroundPath}/>
                            </video>
                        :
                        <></>
                    }
                    <div className="newdes-hero-wrapper">
                        {SessionModel.canEditLanding(landing?.userId) ?
                            <div className="newdes-edit-line">
                                <div className="newdes-click-edit" onClick={() => setShowEdit(true)}>
                                    <div className="newdes-edit-icon"></div>
                                    <p className="newdes-edit-text">Редактировать блок</p>
                                </div>
                            </div>
                            :
                            <></>
                        }
                        <h1 className="newdes-hero-headline">
                            {block.title}
                        </h1>
                        <p className="newdes-hero-description" dangerouslySetInnerHTML={{__html: block.description && block.description.replace(/<\/?[^>]+(>|$)/g, "") ? sanitize(block.description || "Заполните блок") : "Заполните блок"}}/>
                        <div className="newdes-hero-btn-block">
                            <a href={`${window.location.origin}/challenge/${landing?.challenge?.id}/my`} target="_blank"
                               className="newdes-btn">Регистрация/Авторизация</a>
                            {/*<p className="newdes-hero-btn-description">
                                Регистрация стартует {formatDate(landing?.challenge?.startDate)}
                            </p>*/}
                        </div>
                    </div>
                </section>

                {showUpload ?
                    <UploadLogos visible={showUpload} setVisible={setShowUpload} handleLightLogo={handleLightLogo} handleDarkLogo={handleDarkLogo} handleSubmit={upload}/>
                    :
                    <></>
                }

                {showEdit ?
                    <EditTextBlock visible={showEdit} setVisible={setShowEdit} block={block} setLanding={setLanding}/>
                    :
                    <></>
                }
            </>
        )
    } else {
        return (<></>);
    }
}