import React, {useEffect, useState} from "react";
import {ILandingBlock} from "../types/ILandingBlock";
import {ILanding} from "../types/ILanding";
import * as CommonActions from "./common/CommonActions";
import {toNumber} from "lodash";
import SessionModel from "../stores/SessionModel";
import {EditTextBlock} from "./common/EditTextBlock";
import {formatDate} from "./helper/DateHelper";
import {sanitize} from "./common/CommonActions";
import {getBlockDefaults} from "./helper/Util";

export const Description:React.FC<{
    landing: ILanding | undefined,
    setLanding: (landing: ILanding) => void}> = ({landing, setLanding}) => {

    const [block, setBlock] = useState<ILandingBlock | undefined>();
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        setBlock(landing?.blocks?.filter(block => { return block.type === "DESCRIPTION" })[0]);
    }, [landing]);

    if (block) {
        return (
            <>
                <section className={block.isActive ? "newdes-section-standart" : "newdes-section-standart newdes-hidden-block"}>
                    {SessionModel.canEditLanding(landing?.userId) ?
                        <div className="newdes-edit-line">
                            <div className="newdes-click-edit" onClick={() => setShowEdit(true)}>
                                <div className="newdes-edit-icon"></div>
                                <p className="newdes-edit-text" onClick={() => setShowEdit(true)}>Редактировать блок</p>
                            </div>
                            {block.isActive ?
                                <p className="newdes-hidden-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Скрыть блок</p>
                                :
                                <p className="newdes-visible-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Показать</p>
                            }
                        </div>
                        :
                        <></>
                    }
                    <h2 className="newdes-heading-block">
                        {block.title || "Описание"}
                    </h2>
                    <p className="newdes-text-description" dangerouslySetInnerHTML={{__html: block.description && block.description.replace(/<\/?[^>]+(>|$)/g, "") ? sanitize(block.description || getBlockDefaults(block).description || "") : getBlockDefaults(block).description}}/>
                    <ul className="newdes-tag-list">
                        <li className="newdes-tag-item">
                            {formatDate(landing?.challenge?.startDate)} - {formatDate(landing?.challenge?.endDate)}
                        </li>
                        <li className="newdes-tag-item">
                            {landing?.challenge?.challengeType === "COMMAND" ? "Командное участие" : "Индивидуальное участие"}
                        </li>
                    </ul>
                    <div className="newdes-hero-btn-block">
                        <a href={landing?.challenge?.rules}
                           target="_blank" className="newdes-btn">Правила</a>
                        <p className="newdes-hero-btn-description">
                            Ознакомьтесь с правилами мероприятия
                        </p>
                    </div>
                </section>
                {showEdit ?
                    <EditTextBlock visible={showEdit} setVisible={setShowEdit} block={block} setLanding={setLanding}/>
                    :
                    <></>
                }
            </>
        )
    } else {
        return (<></>);
    }
}