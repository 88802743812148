import React, {useEffect, useState} from "react";
import {ILandingBlock} from "../types/ILandingBlock";
import {ILanding} from "../types/ILanding";
import SessionModel from "../stores/SessionModel";
import {toNumber} from "lodash";
import {EditTextBlock} from "./common/EditTextBlock";
import * as CommonActions from "./common/CommonActions";
import {UploadLogos} from "./common/UploadLogos";
import * as LandingAPI from "../requests/LandingAPI";
import {ILandingBlockMediaRequest} from "../types/ILandingBlockMediaRequest";
import {getBase64, getBlockDefaults} from "./helper/Util";
import {ILandingBlockMedia} from "../types/ILandingBlockMedia";
import {CONFIG} from "../CONFIG";
import {sanitize} from "./common/CommonActions";

export const Partners: React.FC<{
    landing: ILanding | undefined,
    setLanding: (landing: ILanding) => void,
    theme: string}> = ({landing, setLanding, theme}) => {

    const [block, setBlock] = useState<ILandingBlock | undefined>();
    const [showEdit, setShowEdit] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [lightLogo, setLightLogo] = useState<FormData>();
    const [darkLogo, setDarkLogo] = useState<FormData>();
    const [mediaRequestDark, setMediaRequestDark] = useState<ILandingBlockMediaRequest>();
    const [mediaRequestLight, setMediaRequestLight] = useState<ILandingBlockMediaRequest>();

    useEffect(() => {
        setBlock(landing?.blocks?.filter(block => { return block.type === "PARTNER" })[0]);
    }, [landing]);

    const handleLightLogo = function (e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;
        if (!fileList) return;
        if (block) {
            getBase64(fileList[0], ((b64) => {
                setMediaRequestLight({...mediaRequestLight, b64: b64, theme: "light", landingBlockId: block.id, remoteMedia: undefined});
            }));
        }

    };

    const handleDarkLogo = function (e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;
        if (!fileList) return;
        if (block) {
            getBase64(fileList[0], ((b64) => {
                setMediaRequestDark({...mediaRequestDark, b64: b64, theme: "dark", landingBlockId: block.id, remoteMedia: undefined});
            }));
        }
    };

    const upload = () => {
        if (mediaRequestDark?.b64 && mediaRequestLight?.b64) {
            LandingAPI.uploadMedia(toNumber(landing?.challengeId), block?.id || "", mediaRequestLight, (response) => {
                if (response.status === 200) {
                    LandingAPI.uploadMedia(toNumber(landing?.challengeId), block?.id || "", mediaRequestDark, (response) => {
                        if (response.status === 200) {
                            setShowUpload(false);
                            setLanding(response.data);
                        }
                    });
                }
            });
        } else if (mediaRequestLight?.b64) {
            LandingAPI.uploadMedia(toNumber(landing?.challengeId), block?.id || "", mediaRequestLight, (response) => {
                if (response.status === 200) {
                    setShowUpload(false);
                    setLanding(response.data);
                }
            });
        } else if (mediaRequestDark?.b64) {
            LandingAPI.uploadMedia(toNumber(landing?.challengeId), block?.id || "", mediaRequestDark, (response) => {
                if (response.status === 200) {
                    setShowUpload(false);
                    setLanding(response.data);
                }
            });
        }
    }

    const deleteLogo = (mediaId: string) => {
            LandingAPI.deleteMedia(toNumber(landing?.challengeId), block?.id || "", mediaId, (response) => {
                if (response.status === 200) {
                    setBlock({...block, mediaList: block?.mediaList ? block?.mediaList.filter(mb => {return mb.id !== mediaId}) || [] : []})
                }
            });
    }

    if (block) {
        return (
            <>
                <section className={block.isActive ? "newdes-section-standart" : "newdes-section-standart newdes-hidden-block"}>
                    {SessionModel.canEditLanding(landing?.userId) ?
                        <div className="newdes-edit-line">
                            <div className="newdes-click-edit" onClick={() => setShowEdit(true)}>
                                <div className="newdes-edit-icon"></div>
                                <p className="newdes-edit-text">Редактировать блок</p>
                            </div>
                            {block.isActive ?
                                <p className="newdes-hidden-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Скрыть блок</p>
                                :
                                <p className="newdes-visible-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Показать</p>
                            }
                        </div>
                        :
                        <></>
                    }
                    <h2 className="newdes-heading-block">
                        {block.title || "Организатор и партнеры"}
                    </h2>
                    <p className="newdes-text-description" dangerouslySetInnerHTML={{__html: block.description && block.description.replace(/<\/?[^>]+(>|$)/g, "") ? sanitize(block.description || getBlockDefaults(block).description || "") : getBlockDefaults(block).description}}/>
                    <ul className="newdes-logo-list-hero">
                        {block.mediaList?.filter(media => { return media.theme === theme }).map((media: ILandingBlockMedia, index) =>
                                <li className="newdes-logo-item-hero" key={index}>
                                    {
                                        SessionModel.canEditLanding(landing?.userId) ?
                                            <div className="newdes-delete" onClick={() => deleteLogo(media.id)}>
                                                <img src={`${CONFIG.PUBLIC_PREFIX}/images/delete_red.svg`} className="newdes-delete-element"/>
                                            </div>
                                            :
                                            <></>
                                    }
                                    {theme === "light" ?
                                        <img src={`${CONFIG.STORAGE_URL}/landing/${landing?.challenge?.id}/media/${media.localMedia}`} alt=""
                                             className="newdes-logo-item-hero-white"/>
                                        :
                                        <img src={`${CONFIG.STORAGE_URL}/landing/${landing?.challenge?.id}/media/${media.localMedia}`} alt=""
                                             className="newdes-logo-item-hero-dark"/>
                                    }
                                </li>
                            )}
                        {SessionModel.canEditLanding(landing?.userId) ?
                            <li className="newdes-logo-item-hero">
                                <div className="newdes-insert-logo">
                                    <div className="newdes-insert-logo-label" onClick={() => setShowUpload(true)}>Добавить логотип</div>
                                </div>
                            </li>
                        :
                            <></>
                        }
                    </ul>
                </section>
                {showEdit ?
                    <EditTextBlock visible={showEdit} setVisible={setShowEdit} block={block} setLanding={setLanding}/>
                    :
                    <></>
                }

                {showUpload ?
                    <UploadLogos visible={showUpload} setVisible={setShowUpload} handleLightLogo={handleLightLogo} handleDarkLogo={handleDarkLogo} handleSubmit={upload}/>
                    :
                    <></>
                }
            </>
        )
    } else {
        return (<></>);
    }
}