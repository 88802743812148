import React, {useEffect, useState} from "react";
import {ILandingBlock} from "../types/ILandingBlock";
import {ILanding} from "../types/ILanding";
import SessionModel from "../stores/SessionModel";
import {toNumber} from "lodash";
import * as CommonActions from "./common/CommonActions";
import {EditTextBlock} from "./common/EditTextBlock";
import * as LandingAPI from "../requests/LandingAPI";
import {CONFIG} from "../CONFIG";
import {EditExpertBlock} from "./common/EditExpertBlock";
import {sanitize} from "./common/CommonActions";
import {getBlockDefaults} from "./helper/Util";

export const Experts: React.FC<{
    landing: ILanding | undefined,
    setLanding: (landing: ILanding) => void}> = ({landing, setLanding}) => {

    const [block, setBlock] = useState<ILandingBlock | undefined>();
    const [showEdit, setShowEdit] = useState(false);
    const [showExpertEdit, setShowExpertEdit] = useState(false);
    const [editableBlock, setEditableBlock] = useState<ILandingBlock | undefined>();

    useEffect(() => {
        setBlock(landing?.blocks?.filter(block => { return block.type === "EXPERT" })[0]);
    }, [landing]);

    const deleteExpert = (blockId: string) => {
        if (landing?.id) {
            LandingAPI.deleteBlock(landing?.id, blockId, (response) => {
                if (response.status === 200) {
                    const subBlocks = block?.subBlocks?.filter(sb => { return sb.id !== blockId});
                    setBlock({...block, subBlocks: subBlocks});
                }
            })
        }
    }

    const editExpert = (visible: boolean, block: ILandingBlock | undefined) => {
        setShowExpertEdit(visible);
        setEditableBlock(block);
    }

    if (block) {
        return (
            <>
                <section className={block.isActive ? "newdes-section-standart" : "newdes-section-standart newdes-hidden-block"} id="experts">
                    {SessionModel.canEditLanding(landing?.userId) ?
                        <div className="newdes-edit-line">
                            <div className="newdes-click-edit" onClick={() => setShowEdit(true)}>
                                <div className="newdes-edit-icon"></div>
                                <p className="newdes-edit-text" onClick={() => setShowEdit(true)}>Редактировать блок</p>
                            </div>
                            {block.isActive ?
                                <p className="newdes-hidden-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Скрыть блок</p>
                                :
                                <p className="newdes-visible-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Показать</p>
                            }
                        </div>
                        :
                        <></>
                    }
                    <h2 className="newdes-heading-block">
                        {block.title || "Эксперты конкурса"}
                    </h2>
                    <p className="newdes-text-description" dangerouslySetInnerHTML={{__html: block.description && block.description.replace(/<\/?[^>]+(>|$)/g, "") ? sanitize(block.description || getBlockDefaults(block).description || "") : getBlockDefaults(block).description}}/>
                    <ul className="newdes-cards-list-jury">
                        {block.subBlocks?.map((subBlock: ILandingBlock, index) =>
                            SessionModel.canEditLanding(landing?.userId) ?
                            <li className="newdes-jury" key={index}>
                                <div className="newdes-delete" onClick={() => deleteExpert(subBlock.id || "")}>
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/images/delete_red.svg`} className="newdes-delete-element"/>
                                </div>
                                <div className="newdes-jury-img-container" style={{cursor: "pointer"}} onClick={() => editExpert(true, subBlock)}>
                                    <img src={`${CONFIG.STORAGE_URL}/landing/${landing?.challenge?.id}/media/${subBlock.mediaList && subBlock.mediaList[0] ? subBlock.mediaList[0].localMedia : ""}`} alt="" className="newdes-img-jury"/>
                                </div>
                                <div className="newdes-text-jury">
                                    <h3 className="newdes-headline-jury">
                                        {subBlock.title}
                                    </h3>
                                    <p className="newdes-text-description">
                                        {subBlock.description}
                                    </p>
                                </div>
                            </li>
                                :
                            <li className="newdes-jury" key={index}>
                                <div className="newdes-jury-img-container">
                                    <img src={`${CONFIG.STORAGE_URL}/landing/${landing?.challenge?.id}/media/${subBlock.mediaList && subBlock.mediaList[0] ? subBlock.mediaList[0].localMedia : ""}`} alt="" className="newdes-img-jury"/>
                                </div>
                                <div className="newdes-text-jury">
                                    <h3 className="newdes-headline-jury">
                                        {subBlock.title}
                                    </h3>
                                    <p className="newdes-text-description">
                                        {subBlock.description}
                                    </p>
                                </div>
                            </li>
                        )}

                        {
                            SessionModel.canEditLanding(landing?.userId) ?
                                <li className="newdes-jury-load" onClick={() => setShowExpertEdit(true)}>
                                    <div className="newdes-load-jury-block">
                                        <div className="newdes-load-video-plus">
                                            <div className="newdes-gor-line"></div>
                                            <div className="newdes-vert-line"></div>
                                        </div>
                                        <p className="newdes-load-video-text">
                                            Добавить жюри или эксперта
                                        </p>
                                    </div>
                                </li>
                                :
                                <></>
                        }
                    </ul>
                </section>

                {showEdit ?
                    <EditTextBlock visible={showEdit} setVisible={setShowEdit} block={block} setLanding={setLanding}/>
                    :
                    <></>
                }
                {showExpertEdit ?
                    <EditExpertBlock landing={landing} block={block} subBlock={editableBlock} visible={showExpertEdit} setVisible={editExpert} setLanding={setLanding}/>
                    :
                    <></>
                }
            </>
        )
    } else {
        return (<></>)
    }
}